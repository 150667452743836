<template>
  <div class="data-deletion-page mx-auto max-w-2xl p-5 font-sans">
    <h1 class="text-2xl mb-2">Data Deletion Instructions</h1>
    <p class="text-lg leading-relaxed mb-5">
      We value your privacy and want to ensure you have control over your data. Follow the steps below to delete your data:
    </p>
    <ol class="ml-5 list-decimal">
      <li class="mb-2">Log into your account on our platform.</li>
      <li class="mb-2">Navigate to the 'Account Settings' section.</li>
      <li class="mb-2">Find the 'Delete Account' option at the bottom of the page.</li>
      <li class="mb-2">Click on 'Delete Account' and follow the confirmation steps.</li>
      <li class="mb-2">Your data will be permanently deleted within 24 hours.</li>
    </ol>
    <p class="text-lg leading-relaxed">
      If you encounter any issues, please contact our support team for assistance.
    </p>
  </div>
</template>

<script>
export default {
  name: 'DataDeletionPage',
};
</script>

<style scoped>
/* Remove existing styles since Tailwind CSS is now used */
</style>
